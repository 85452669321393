import { useEffect, useState } from 'react'
import logo from '../IMAGES/sbnc-dems-logo.png'
import { Clickable } from './Clickable'
import { AiOutlineMenu } from "react-icons/ai";
import { useNavigate } from 'react-router';
import { AiOutlineClose } from "react-icons/ai";
import { MdArrowOutward } from "react-icons/md";

export function Navigation() {
    const navigate = useNavigate();
    const [showBurger, setShowBurger] = useState(false);
    const [showNav, setShowNav] = useState(false);

    function onResize() {
        if (window.innerWidth > 500) {
            setShowBurger(false);
        } else {
            setShowBurger(true)
        }
    }

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [])

    return <div className="poppins">
        <div className='separate-h'>
            <Clickable onPress={() => {
                navigate('/')
            }}>
                <img src={logo} style={{ width: 100, height: 100 }} />

            </Clickable>
            {/* BURGER */}
            {
                showBurger && <Clickable onPress={() => {
                    setShowNav(true)
                }}>
                    <div className='p'>
                        <AiOutlineMenu size={30} />
                    </div>
                </Clickable>
            }

            {/* NOT BURGER */}
            {
                !showBurger && <div className='flex-row'>
                    <Clickable onPress={() => {
                        navigate('/about')
                    }}>
                        <p className='p'>About Us</p>
                    </Clickable>
                    <Clickable onPress={() => {
                        navigate('/events')
                    }}>
                        <p className='p'>Events</p>
                    </Clickable>
                    <Clickable onPress={() => {
                        navigate('/contact')
                    }}>
                        <p className='p bg-black white'>Contact Us</p>
                    </Clickable>
                </div>
            }

            {/* NAV */}
            {
                showNav && <div className='fixed top right full-width bg-white full-height' style={{ zIndex: 100, maxWidth: 450 }}>
                    <div className='separate-h p'>
                        <img src={logo} style={{ width: 100, height: 100 }} />
                        <Clickable onPress={() => {
                            setShowNav(false)
                        }}>
                            <AiOutlineClose size={40} />
                        </Clickable>
                    </div>
                    <div className=''>
                        <Clickable onPress={() => {
                            navigate('/')
                        }}>
                            <div className='p separate-h vertical-center border-b-1-black'>
                                <p className='text-lg'>Home</p>
                                <MdArrowOutward size={30} />
                            </div>
                        </Clickable>
                        <Clickable onPress={() => {
                            navigate('/about')
                        }}>
                            <div className='p separate-h vertical-center border-b-1-black'>
                                <p className='text-lg'>About Us</p>
                                <MdArrowOutward size={30} />
                            </div>
                        </Clickable>
                        <Clickable onPress={() => {
                            navigate('/events')
                        }}>
                            <div className='p separate-h vertical-center border-b-1-black'>
                                <p className='text-lg'>Events</p>
                                <MdArrowOutward size={30} />
                            </div>
                        </Clickable>
                        <Clickable onPress={() => {
                            navigate('/contact')
                        }}>
                            <div className='p separate-h vertical-center border-b-1-black'>
                                <p className='text-lg'>Contact Us</p>
                                <MdArrowOutward size={30} />
                            </div>
                        </Clickable>
                    </div>
                </div>
            }
        </div>
    </div>
}