import { Background } from "../UTILITIES/Background";
import { Navigation } from "../UTILITIES/Navigation";
import { Footer } from '../UTILITIES/Footer'
import { Clickable } from "../UTILITIES/Clickable";
import { useEffect } from "react";

export function Events() {

    useEffect(() => {
        document.title = `Events - South Bay National City Democrats`
    }, [])

    const events = [
        {
            month: 'Jan',
            day: '20',
            time: '@6:30 PM',
            details: 'Meeting at the Olivewood Clubhouse. Below is the agenda for this meeting.',
            link: 'https://drive.google.com/file/d/1dn5CxnIK2x2MiiX14vvMUnKPtZrfHmz6/view?usp=drive_link'
        },
    ];

    return <div className="poppins">

        <Navigation />
        <div className="main">
            {/*  */}
            <div className="p">
                <h1 className="text-xl normal bg-black inline white">Upcoming Events</h1>
            </div>

            {
                events.map((eve, i) => {
                    return <div className="row p gap box" key={i}>

                        <div className="bg-black p-h box">
                            <h1 className="white normal">{eve.month}</h1>
                            <p className="white" style={{ fontSize: 80 }}>{eve.day}</p>
                        </div>
                        <div className="box lg2 box">
                            <h4 className="normal text-md">{eve.time}</h4>
                            <p className="box">{eve.details}</p>
                            <br />
                            {
                                eve.link != "" && <Clickable onPress={() => {
                                    window.open(`${eve.link}`, '_black')
                                }}>
                                    <p className="blue underline">Open Link</p>
                                </Clickable>
                            }
                        </div>
                    </div>
                })
            }

        </div>

        <Background />
        <Footer />
    </div>
}