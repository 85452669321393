import { Navigation } from "../UTILITIES/Navigation";

import home1 from '../IMAGES/sbnc-dems-group.jpeg'
import { Background } from "../UTILITIES/Background";
import { Spacer } from "../UTILITIES/Spacer";
import { Footer } from "../UTILITIES/Footer";
import { useEffect } from "react";

export function Home() {
    useEffect(() => {
        document.title = 'South Bay National City Democrats';
    }, [])
    return <div className="poppins">
        <Navigation />
        <div className="main box">
            <div className="row box">
                <div className="lg2 xl2 p box gap">
                    <h1 className="text-xl normal bg-black white inline">South Bay National City Democrats</h1>
                    <br /><br />
                    <p className="thin">The South Bay - National City Democrats (SBNCD) is your gateway to civic engagement, providing a platform for promoting Democratic Party values, addressing pressing community issues, and influencing policy at local and state levels. Our vibrant club hosts forums for Democratic candidates, takes stands on important propositions, and empowers members to discuss and act on emerging social challenges. By joining SBNCD, you become part of a movement dedicated to fostering political participation and driving meaningful change.</p>
                    <br />
                    <p className="thin">Membership in the SBNCD is open to all registered Democrats or those planning to register. As a member, you’ll have the opportunity to vote, run for office, and shape the direction of our club. Annual dues are affordable—just $15 for individuals and $5 for seniors, students, or those with limited income. With general meetings held at least seven times a year and a variety of committees to join, your voice will be heard, and your efforts will make a difference. Don’t wait—step up and help us build a brighter future for our community and beyond.</p>
                </div>
                <div className="xl2">
                    <img src={home1} className="radius" style={{ width: '100%', height: 'auto', objectFit: 'cover', display: 'block' }} />
                </div>

            </div>
            <Spacer />
            <div className="row gap">
                <div className="box p border-1-black">
                    <h2 className="p-sm normal">Purpose</h2>
                    <p className="thin">The South Bay - National City Democrats (SBNCD) aims to promote the values of the Democratic Party, foster political participation, and provide a platform to discuss and address local and state issues. From candidate forums to community issue advocacy, we empower members to take meaningful action.</p>
                </div>
                <div className="box p border-1-black">
                    <h2 className="p-sm normal">Membership</h2>
                    <p className="thin">Becoming a member of the SBNCD is easy and impactful. Open to all registered Democrats or those intending to register, membership offers opportunities to vote, hold office, and shape the club’s direction. Annual dues are affordable, with special rates for seniors, students, and those with limited income.</p>
                </div>
                <div className="box p border-1-black">
                    <h2 className="p-sm normal">Meetings</h2>
                    <p className="thin">The SBNCD holds at least seven general meetings annually to discuss business, vote on resolutions, and hear from candidates. Members receive 14 days’ notice for meeting details, and decisions are made with a quorum present, ensuring every voice counts in our democratic process.</p>
                </div>
                <div className="box p border-1-black">
                    <h2 className="p-sm normal">Candidate Endorsements</h2>
                    <p className="thin">The SBNCD proudly endorses Democratic candidates and ballot measures through a transparent, member-driven process. Members attending at least three meetings within a year are eligible to vote on endorsements, requiring a two-thirds majority for approval.</p>
                </div>

            </div>
            <Spacer height={75} />

        </div>


        <Background />

        <Footer />
    </div>
}