import { useEffect } from "react";
import { Background } from "../UTILITIES/Background";
import { Footer } from "../UTILITIES/Footer";
import { Navigation } from "../UTILITIES/Navigation";

export function Contact() {

    useEffect(() => {
        document.title = 'Contact Us - South Bay National City Democrats'
    }, [])

    return <div className="poppins">
        <Navigation />
        <div className="main">
            {/*  */}
            <div className="row box">
                <div className="md2 p box">
                    <h1 className="normal">Contact Us</h1>
                    <p className="box">Thank you for visiting our contact page! Whether you have questions, want to get involved, or share your thoughts, we’re here to listen and support. Reach out to us, and together, we can work toward a brighter, more inclusive future!</p>
                </div>
            </div>
            <br />
            <div className="p box">
                <h2 className="text-xl normal spacing-sm" style={{ overflowWrap: "break-word" }}>sbncdems@gmail.com</h2>
            </div>
        </div>

        <Background />
        <Footer />
    </div >
}