import { useNavigate } from "react-router";
import { Clickable } from "./Clickable";
import { RiInstagramFill } from "react-icons/ri";

export function Footer() {
    const navigate = useNavigate()

    return <div className="poppins p-lg box" style={{ backgroundColor: '#044585' }}>
        <h1 className="thin white line-sm text-center">South Bay National City Democrats</h1>
        <br />
        <div className="text-center">
            <Clickable onPress={() => {
                window.location.href = 'https://www.instagram.com/sbncdems/';
            }}>
                <RiInstagramFill size={35} color="white" />
            </Clickable>
        </div>
        <br />
        <div>
            <p className="text-xs white text-center">copyright SBNC Dems. All Rights Reserved 2024.</p>
        </div>
    </div>
}