import { useEffect } from "react";
import { Background } from "../UTILITIES/Background";
import { Clickable } from "../UTILITIES/Clickable";
import { Footer } from "../UTILITIES/Footer";
import { Navigation } from "../UTILITIES/Navigation";

export function About() {

    useEffect(() => {
        document.title = "About Us - South Bay National City Democrats"
    }, [])

    return <div className="poppins">
        <Navigation />
        <div className="main">
            {/*  */}
            <div className="p">
                <h1 className="text-xl normal bg-black inline white">About Us</h1>
            </div>
            <div className="row">
                <div className="md2 p">
                    <h2 className="normal blue">President</h2>
                    <p>Cindy Lopez</p>
                    <h3 className="thin blue">Vice President</h3>
                    <p>Jose Cerda</p>
                    <h3 className="thin blue">Director of Finance</h3>
                    <p>Barbara Avalos</p>
                    <h3 className="thin blue">Secretary</h3>
                    <p>Adriana Huerta</p>
                    {/* <h3 className="thin blue">Director of Membership</h3>
                    <p>Malik Thornton</p>
                    <h3 className="thin blue">Director of Communications</h3>
                    <p>Jasmine Truong</p>
                    <h3 className="thin blue">Director of Community Service</h3>
                    <p>Malachi Bielecki</p> */}
                </div>
            </div>
            <div className="row box p gap border-b-1-black">
                <h2 className="normal">Bylaws</h2>
                <p className="lg2 xl3">For a detailed overview of the South Bay Bylaws, please refer to this document.</p>
                <br />
                <Clickable onPress={() => {
                    window.open('https://drive.google.com/file/d/1_ZaSdcRK-T05gi-Z_e1ZXKiNOePNSI2j/view?usp=drive_link', '_blank')
                }}>
                    <p className="blue">Bylaws Document</p>
                </Clickable>
            </div>
            <div className="row box p gap border-b-1-black">
                <h2 className="normal">Purpose</h2>
                <p className="lg2 xl3">The South Bay - National City Democrats (SBNCD) aims to promote the ideals of the Democratic Party, encourage political participation, and inform both members and the public about political issues. This includes supporting or opposing local and state propositions, providing forums for Democratic candidates, and discussing emerging community and social issues.</p>
            </div>

            <div className="row box p gap border-b-1-black">
                <h2 className="normal">Membership</h2>
                <p className="lg2 xl3">Anyone who is a registered Democrat, or who intends to register as a Democrat, is eligible for membership in the SBNCD. To vote or hold office, a member must pay annual dues and be a member for at least 60 days. The annual dues are $15 for individuals and $5 for seniors, students, or those with limited income. Dues are payable upon joining and must be renewed annually by January 1st. If dues are not paid by March 1st, the member’s status may be affected. Members can vote via absentee ballots for proposed changes, resolutions, and elections, but proxy voting is not allowed.</p>
            </div>

            <div className="row box p gap border-b-1-black">
                <h2 className="normal">Officers</h2>
                <p className="lg2 xl3">The SBNCD has four elected officers: president, vice president, secretary, and treasurer. Officers serve for one year and are responsible for running meetings, managing finances, and handling member relations. Elections are held annually, and a committee oversees the election process. Officers are elected by a majority vote of the members. If an officer misses three meetings in a row or fails to perform their duties, the executive committee may declare the office vacant and appoint a replacement, subject to approval by the membership. The president may appoint additional officers, such as a parliamentarian, to help manage the club, and these officers are subject to ratification by the membership. Delegates to the Democratic State Central Committee are chosen by the club members and must meet residency and eligibility requirements.</p>
            </div>

            <div className="row box p gap border-b-1-black">
                <h2 className="normal">Meetings</h2>
                <p className="lg2 xl3">The SBNCD holds at least seven general meetings per year, where members discuss business, vote on resolutions, and hear from candidates. These meetings are typically scheduled with at least 14 days’ notice to members.</p>
            </div>

            <div className="row box p gap border-b-1-black">
                <h2 className="normal">Standing Committees</h2>
                <p className="lg2 xl3">The SBNCD has several standing committees, including public relations, political action, membership, and fundraising. The president appoints committee chairs and members as needed.</p>
            </div>

            <div className="row box p gap border-b-1-black">
                <h2 className="normal">Candidate Endorsement</h2>
                <p className="lg2 xl3">The SBNCD endorses Democratic candidates and ballot measures. Endorsements are made through a vote of members who have attended at least three meetings in the past year. A two-thirds majority vote is required for an endorsement.</p>
            </div>

            <div className="row box p gap border-b-1-black">
                <h2 className="normal">Affiliation</h2>
                <p className="lg2 xl3">The SBNCD affiliates as an associate member of the San Diego County Democratic Party. The president serves as the club’s representative on the county’s central committee unless another member is appointed.</p>
            </div>

            <div className="row box p gap border-b-1-black">
                <h2 className="normal">Amendments to the Bylaws</h2>
                <p className="lg2 xl3">The bylaws may be amended by a 60% majority vote of members at a general meeting, as long as the proposed amendment has been presented at the previous meeting.</p>
            </div>

            <div className="row box p gap border-b-1-black">
                <h2 className="normal">Adoption of Amendments</h2>
                <p className="lg2 xl3">Once adopted, amendments to the bylaws take effect immediately. The club secretary must submit a copy of the amended bylaws to the San Diego County Democratic Party within 10 business days. </p>
            </div>
        </div>

        <Background />

        <Footer />
    </div>
}